import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Legend = styled(({ className, el, state }) => {
  // https://gka.github.io/palettes/#colors=#ff9138,#504bff|steps=7|bez=1|coL=1
  const scale = [
    "#ff9138",
    "#ffb67d",
    "#ffd6ba",
    "#f7f7f7",
    "#cdbbfc",
    "#9b83fe",
    "#504bff",
  ];
  const categories = ["#ff9138", "#504bff"];
  return ReactDOM.createPortal(
    <div className={className + " mapboxgl-ctrl"}>
      <strong>OSM Completeness</strong>
      {state.zoom < 13 ? (
        <div className="scale">
          {scale.map((color) => (
            <div
              key={color}
              className="color"
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      ) : (
        <div className="categories">
          {categories.map((color) => (
            <div
              key={color}
              className="color"
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      )}
      {state.zoom < 13 ? (
        <div className="scale-label">
          <span>0%</span>
          <span>100%</span>
        </div>
      ) : (
        <div className="categories-label">
          <span>Unmapped</span>
          <span>Mapped</span>
        </div>
      )}
    </div>,
    el
  );
})`
  display: flex;
  flex-direction: column;
  line-height: 1;

  @media screen and (min-width: 480px) {
    padding-bottom: 10px;
  }

  > strong {
    margin-bottom: 1em;
  }

  > .scale > .color {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
  }

  > .scale-label {
    display: flex;
    justify-content: space-between;
  }

  > .categories {
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-around;
    width: 10em;
  }

  > .categories > .color {
    width: 3em;
    height: 3em;
    display: inline-block;
    border: 1px solid white;
  }

  > .categories-label {
    display: flex;
    font-size: 0.8em;
  }

  > .categories-label > span {
    flex: 1;
    white-space: nowrap;
    text-align: center;
  }
`;

export default Legend;
