import React from "react";
import styled from "styled-components";

const EditButton = styled(({ className, state }) => {
  const osmEditURL = `https://www.openstreetmap.org/edit#map=${state.zoom}/${state.lat}/${state.lng}`;
  return state.zoom >= 13 ? (
    <div className={className}>
      <a
        className="btn"
        href={osmEditURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Map this area on <strong>OpenStreetMap</strong>
      </a>
    </div>
  ) : null;
})`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .btn {
    display: inline-block;
    border: 1px solid;
    border-radius: 3px;
    padding: 0.5em 1em;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);
    pointer-events: auto;
  }

  .btn:hover {
    background: white;
    color: black;
    border-color: transparent;
  }
`;

export default EditButton;
