import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";

import Sidebar from "./Sidebar";
import Legend from "./Legend";
import Credits from "./Credits";
import EditButton from "./EditButton";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: aktiv-grotesk, sans-serif;
    color: white;
  }

  a {
    color: inherit;
  }
`;

const App = ({ map, controls }) => {
  const [state, setState] = useState({
    ...map.getCenter(),
    zoom: parseInt(map.getZoom(), 10),
    showAbout: true,
  });
  useEffect(() => {
    function update(data) {
      setState((prevState) => ({
        ...prevState,
        ...map.getCenter(),
        zoom: parseInt(map.getZoom(), 10),
      }));
    }
    map.on("moveend", update);
    map.on("zoomend", update);
    return function cleanup() {
      map.off("moveend", update);
      map.off("zoomend", update);
    };
  });
  function toggleAbout(e) {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      showAbout: !prevState.showAbout,
    }));
  }
  return (
    <React.Fragment>
      <GlobalStyle />
      <Sidebar
        el={controls["top-left"].el}
        state={state}
        toggleAbout={toggleAbout}
      />
      <Legend el={controls["bottom-left"].el} state={state} />
      <Credits el={controls["bottom-right"].el} />
      <EditButton state={state} />
    </React.Fragment>
  );
};

export default App;
