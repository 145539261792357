import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Sidebar = styled(({ className, el, state, toggleAbout }) => {
  return ReactDOM.createPortal(
    <div className={className + " mapboxgl-ctrl"}>
      <h1>Mapping the gaps in OpenStreetMap</h1>
      {state.showAbout ? (
        <React.Fragment>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.openstreetmap.org/"
            >
              OpenStreetMap (OSM)
            </a>{" "}
            is a free, open, and crowdsourced counterpart to commercial map
            services, such as Google Maps, which charge users for access to
            their underlying data.
          </p>
          <p>
            Often called the Wikipedia of Maps, OpenStreetMap data is
            contributed by volunteer mappers. But OSM data is more complete in
            some areas than in others.
          </p>
          <p>
            This interactive map visualizes OSM completeness across the
            Philippines.
          </p>
          <p>
            To estimate an area’s level of “OSM completeness,” we calculated the
            percentage of built-up lands that also have buildings on OSM. In
            urban planning, “built-up areas” are lands on which buildings are
            present. To identify built up areas, we used a dataset from{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://code.fb.com/connectivity/open-population-datasets-and-open-challenges/"
            >
              Connectivity Lab at Facebook
            </a>{" "}
            that extracts building locations from satellite imagery.
          </p>
          <p>
            You can download the aggregated OSM completeness results from these
            links:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://storage.googleapis.com/osm-completeness-thinkingmachines/mapthegap-phl-adm2-2020-06-13.csv"
            >
              province-level
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://storage.googleapis.com/osm-completeness-thinkingmachines/mapthegap-phl-adm3-2020-06-13.csv"
            >
              municipality-level
            </a>
            , and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://storage.googleapis.com/osm-completeness-thinkingmachines/mapthegap-phl-adm4-2020-06-13.csv"
            >
              barangay-level
            </a>
            .
          </p>
          <p>
            Want to help <strong>#MapTheGap</strong>? Zoom in on unmapped areas
            to find buildings you can add to OSM.
          </p>
          <p>(last updated on 2020-06-13)</p>
        </React.Fragment>
      ) : null}
      <p>
        <a className="toggle" href="#about" onClick={toggleAbout}>
          {state.showAbout ? "Hide text" : "Read more..."}
        </a>
      </p>
    </div>,
    el
  );
})`
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5em;
  max-width: 30em;

  &.mapboxgl-ctrl {
    margin-left: 0;
    margin-top: 0;
  }

  h1 {
    margin-top: 0;
    font-family: merriweather, serif;
    line-height: 1.2;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .toggle {
    display: inline-block;
    padding: 0 0.5em;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    text-decoration: none;
    color: black;
  }

  .toggle:hover {
    background: white;
  }
`;

export default Sidebar;
