import "sanitize.css";
import "mapbox-gl/dist/mapbox-gl.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGhpbmtkYXRhc2NpIiwiYSI6ImNrcnZ2cG93ajBhbTgyd28yMjFnOHlrb3oifQ.4BkTmlaoujzbIz9XMAdMCw";

const map = new mapboxgl.Map({
  container: "map",
  style: "mapbox://styles/thinkdatasci/cjynon2783lf91cravymueqdn",
  center: [120.454, 12.897],
  zoom: 5,
});

function addSources(map) {
  map.addSource("level2_province", {
    type: "vector",
    url: "mapbox://thinkdatasci.aza0bqgm",
  });
  map.addSource("level3_municipality", {
    type: "vector",
    url: "mapbox://thinkdatasci.bnd0o86p",
  });
  map.addSource("osm-does-not-exist", {
    type: "raster",
    url: "mapbox://thinkdatasci.4oi26ovj",
  });
  map.addSource("osm-exists", {
    type: "raster",
    url: "mapbox://thinkdatasci.bef8gxek",
  });
  map.addSource("mapbox-streets", {
    type: "vector",
    url: "mapbox://mapbox.mapbox-streets-v8",
  });
}

function addLayers(map) {
  map.addLayer(
    {
      id: "level2_province",
      source: "level2_province",
      "source-layer": "level2_province",
      maxzoom: 9,
      type: "fill",
      paint: {
        "fill-color": [
          "interpolate",
          ["linear"],
          ["get", "percentage_completeness"],
          0,
          "#ff9138",
          50,
          "#f7f7f7",
          100,
          "#504bff",
        ],
        "fill-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.8, 9, 0],
        "fill-outline-color": "white",
      },
    },
    "road-label"
  );
  map.addLayer(
    {
      id: "level3_municipality",
      source: "level3_municipality",
      "source-layer": "level3_municipality",
      minzoom: 7,
      maxzoom: 13,
      type: "fill",
      paint: {
        "fill-color": [
          "interpolate",
          ["linear"],
          ["get", "percentage_completeness"],
          0,
          "#ff9138",
          50,
          "#f7f7f7",
          100,
          "#504bff",
        ],
        "fill-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          0,
          9,
          0.8,
          13,
          0,
        ],
        "fill-outline-color": "white",
      },
    },
    "level2_province"
  );
  map.addLayer(
    {
      id: "osm-does-not-exist",
      source: "osm-does-not-exist",
      "source-layer": "mapthegap-phl-dne-2020-06-13-2vucst",
      minzoom: 9,
      type: "raster",
      paint: {
        "raster-opacity": 0.8,
      },
    },
    "level3_municipality"
  );
  map.addLayer(
    {
      id: "osm-exists",
      source: "osm-exists",
      "source-layer": "mapthegap-phl-exist-2020-06-1-bkifys",
      minzoom: 9,
      maxzoom: 18,
      type: "raster",
      paint: {
        "raster-opacity": ["interpolate", ["linear"], ["zoom"], 15, 0.8, 18, 0],
      },
    },
    "level3_municipality"
  );
  map.addLayer(
    {
      id: "buildings",
      source: "mapbox-streets",
      "source-layer": "building",
      minzoom: 15,
      type: "fill",
      paint: {
        "fill-color": "#504bff",
        "fill-opacity": 0.8,
      },
    },
    "level3_municipality"
  );
}

function ReactControl() {}

ReactControl.prototype.onAdd = function () {
  this.el = document.createElement("div");
  return this.el;
};

ReactControl.prototype.onRemove = function () {
  this.el.parentNode.removeChild(this.el);
};

map.on("load", function () {
  addSources(map);
  addLayers(map);
  const positions = ["top-left", "top-right", "bottom-right", "bottom-left"];
  const controls = positions.reduce((controls, pos) => {
    const control = new ReactControl();
    map.addControl(control, pos);
    return {
      ...controls,
      [pos]: control,
    };
  }, {});
  map.addControl(new mapboxgl.NavigationControl());
  ReactDOM.render(
    <App map={map} controls={controls} />,
    document.getElementById("react")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
