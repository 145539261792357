import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { ReactComponent as Logo } from "./logo.svg";

const Credits = styled(({ className, el }) => {
  return ReactDOM.createPortal(
    <div className={className + " mapboxgl-ctrl"}>
      From your friends at{" "}
      <strong>
        <a href="https://thinkingmachin.es?src=mapthegap">
          <Logo /> Thinking
          <br />
          Machines
        </a>
      </strong>
    </div>,
    el
  );
})`
  @media screen and (max-width: 480px) {
    padding-bottom: 5px;
  }

  a {
    text-decoration: none;
    display: flex;
    line-height 1;
    align-items: center;
    font-size: 1.3em;
  }

  svg {
    height: 3em;
    margin-right: 0.5em;
  }
`;

export default Credits;
